.checkbox {
	font-weight: 400;
	position: relative;
	display: block;
	line-height: 18px;
	span {
		padding-left: 15px;
		&:empty {
			float: left;
		}
		&:after {
			line-height: 18px;
			position: absolute;
		}
		&:before {
			line-height: 18px;
			position: absolute;
			content: '';
			width: 16px;
			height: 16px;
			background-color: $background;
			border: 1px solid #e8ebf3;
			top: 0px;
			left: 0;
		}
		&:after {
			top: 0px;
			left: 0;
			width: 16px;
			height: 16px;
			content: '\f00c';
			font-family: FontAwesome;
			font-size: 9px;
			text-align: center;
			color: $white;
			line-height: 17px;
			display: none;
			border-radius:3px;
		}
	}
	input[type=checkbox] {
		opacity: 0;
		margin: 0 5px 0 0;
		&:checked+span:after {
			display: block;
		}
		&[disabled]+span {
			opacity: .75;
			&:after,
			&:before {
				opacity: .75;
			}
		}
	}
}

.checkbox-inline {
	display: inline-block;
}

.form-checkbox .custom-control-label::before {
	border-radius: 3px;
}

.br.form-checkbox .custom-control-label::before {
	border-radius: 50px;
}

.form-checkbox .custom-control-input {
	&:checked~.custom-control-label::after {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
	}
	&:indeterminate~.custom-control-label::after {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
	}	
}

.custom-radio {
	.custom-control-label::before {
		border-radius: 50%;
	}
	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
		}
	}
}