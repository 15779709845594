/*----- Ratings -----*/

.rating-stars {
	.rating-stars-container {
		font-size: 0;
		.rating-star {
			display: inline-block;
			font-size: 32px;
			cursor: pointer;
			padding: 5px 8px;
			color: #ebeefb;
			&.sm {
				display: inline-block;
				font-size: 14px;
				color: #83829c;
				cursor: pointer;
				padding: 1px;
			}
			&.is--active,
			&.is--hover {
				color: #f1c40f;
			}
			&.is--no-hover,
			.fa-heart .is--no-hover {
				color: #3e4b5b;
			}
		}
	}
	input {
		display: none;
		margin: 0 auto;
		text-align: center;
		padding: .375rem .75rem;
		font-size: .9375rem;
		line-height: 1.6;
		color: #3d4e67;
		background-color: $white;
		background-clip: padding-box;
		border: 1px solid #e8ebf3;
		border-radius: 3px;
		transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	}
	&.star input {
		display: none;
	}
}
.br-theme-bars-square {
	.br-widget {
		height: 15px;
		white-space: nowrap;
		a {
			display: block;
			width: 30px;
			height: 30px;
			float: left;
			margin: 2px;
			text-decoration: none;
			font-size: 14px;
			font-weight: 400;
			line-height: 2;
			text-align: center;
			font-weight: 600;
		}
		.br-current-rating {
			clear: both;
			width: 330px;
			text-align: center;
			font-weight: 600;
			display: block;
			padding: .5em 0;
			color: #646464;
		}
	}
	.br-readonly a {
		cursor: default;
	}
}
@media print {
	.br-theme-bars-square .br-widget a {
		border: 2px solid #b3b3b3;
		color: #b3b3b3;
		&.br-active, &.br-selected {
			border: 2px solid black;
			color: black;
		}
	}
}
.br-theme-bootstrap-stars {
	.br-widget {
		height: 28px;
		white-space: nowrap;
		a {
			font: normal normal normal 18px/1 'Glyphicons Halflings';
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			text-decoration: none;
			margin-right: 2px;
			&:after {
				content: '\e006';
				color: #d2d2d2;
			}
			&.br-active:after, &.br-selected:after {
				color: #EDB867;
			}
		}
		.br-current-rating {
			display: none;
		}
	}
	.br-readonly a {
		cursor: default;
	}
}
@media print {
	.br-theme-bootstrap-stars .br-widget a {
		&:after {
			content: '\e007';
			color: black;
		}
		&.br-active:after, &.br-selected:after {
			content: '\e006';
			color: black;
		}
	}
}
.br-theme-css-stars {
	.br-widget {
		height: 28px;
		white-space: nowrap;
		a {
			text-decoration: none;
			height: 18px;
			width: 18px;
			float: left;
			font-size: 23px;
			margin-right: 5px;
			&:after {
				content: "\2605";
				color: #d2d2d2;
			}
			&.br-active:after, &.br-selected:after {
				color: #EDB867;
			}
		}
		.br-current-rating {
			display: none;
		}
	}
	.br-readonly a {
		cursor: default;
	}
}
@media print {
	.br-theme-css-stars .br-widget a {
		&:after {
			content: "\2606";
			color: black;
		}
		&.br-active:after, &.br-selected:after {
			content: "\2605";
			color: black;
		}
	}
}
.br-theme-fontawesome-stars {
	.br-widget {
		white-space: nowrap;
		a {
			font: normal normal normal 20px/1 FontAwesome;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			text-decoration: none;
			margin-right: 2px;
		}
		.br-current-rating {
			display: none;
		}
	}
	.br-readonly a {
		cursor: default;
	}
	.br-widget a {
		&:after {
			content: '\f005';
			color: #e5e9f2;
		}
		&.br-active:after, &.br-selected:after {
			color: #ffb609;
		}
	}
}
@media print {
	.br-theme-fontawesome-stars .br-widget a {
		&:after {
			content: '\f006';
			color: black;
		}
		&.br-active:after, &.br-selected:after {
			content: '\f005';
			color: black;
		}
	}
}
.stars-example-fontawesome-sm .br-theme-fontawesome-stars .br-widget a {
	font: normal normal normal 14px/1 FontAwesome;
	position: relative;
}
.br-theme-fontawesome-stars-o {
	.br-widget {
		height: 28px;
		white-space: nowrap;
		a {
			font: normal normal normal 20px/1 FontAwesome;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			text-decoration: none;
			margin-right: 2px;
		}
		.br-current-rating {
			display: none;
		}
	}
	.br-readonly a {
		cursor: default;
	}
	.br-reverse a.br-fractional {
		display: inline-block;
		transform: scaleX(-1);
		-moz-transform: scaleX(-1);
		-webkit-transform: scaleX(-1);
		filter: FlipH;
		-ms-filter: "FlipH";
	}
}
@media print {
	.br-theme-fontawesome-stars-o .br-widget a {
		&:after {
			content: '\f006';
			color: black;
		}
		&.br-active:after, &.br-selected:after {
			content: '\f005';
			color: black;
		}
		&.br-fractional:after {
			content: '\f123';
			color: black;
		}
	}
}
.br-theme-bars-1to10 {
	.br-widget {
		height: 50px;
		white-space: nowrap;
		a {
			display: block;
			width: 12px;
			padding: 5px 0;
			height: 28px;
			float: left;
			margin: 1px;
			text-align: center;
		}
		.br-current-rating {
			font-size: 20px;
			line-height: 2;
			float: left;
			padding: 0 20px 0 20px;
			font-weight: 400;
		}
	}
	.br-readonly a {
		cursor: default;
	}
}
@media print {
	.br-theme-bars-1to10 .br-widget {
		a {
			border: 1px solid #b3b3b3;
			background: white;
			height: 38px;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			&.br-active, &.br-selected {
				border: 1px solid black;
				background: white;
			}
		}
		.br-current-rating {
			color: black;
		}
	}
}
.br-theme-bars-horizontal {
	.br-widget {
		width: 120px;
		white-space: nowrap;
		a {
			display: block;
			width: 120px;
			height: 5px;
			margin: 1px;
		}
		.br-current-rating {
			width: 120px;
			font-size: 18px;
			font-weight: 600;
			line-height: 2;
			text-align: center;
		}
	}
	.br-readonly a {
		cursor: default;
	}
}
@media print {
	.br-theme-bars-horizontal .br-widget {
		a {
			border: 1px solid #b3b3b3;
			background: white;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			&.br-active, &.br-selected {
				border: 1px solid black;
				background: white;
			}
		}
		.br-current-rating {
			color: black;
		}
	}
}
.br-theme-bars-movie {
	.br-widget {
		height: 10px;
		white-space: nowrap;
		a {
			display: block;
			width: 60px;
			height: 8px;
			float: left;
			margin: 1px;
		}
		.br-current-rating {
			clear: both;
			width: 240px;
			text-align: center;
			font-weight: 600;
			display: block;
			padding: .5em 0;
			font-weight: 400;
		}
	}
	.br-readonly a {
		cursor: default;
	}
}
@media print {
	.br-theme-bars-movie .br-widget {
		a {
			border: 1px solid #b3b3b3;
			background: white;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			&.br-active, &.br-selected {
				border: 1px solid black;
				background: white;
			}
		}
		.br-current-rating {
			color: black;
		}
	}
}
.br-theme-bars-pill {
	.br-widget {
		white-space: nowrap;
		a {
			padding: 7px 15px;
			text-decoration: none;
			font-size: 13px;
			line-height: 3;
			text-align: center;
			font-weight: 400;
			&:first-child {
				-webkit-border-top-left-radius: 999px;
				-webkit-border-bottom-left-radius: 999px;
				-moz-border-radius-topleft: 999px;
				-moz-border-radius-bottomleft: 999px;
				border-top-left-radius: 999px;
				border-bottom-left-radius: 999px;
			}
			&:last-child {
				-webkit-border-top-right-radius: 999px;
				-webkit-border-bottom-right-radius: 999px;
				-moz-border-radius-topright: 999px;
				-moz-border-radius-bottomright: 999px;
				border-top-right-radius: 999px;
				border-bottom-right-radius: 999px;
			}
		}
	}
	.br-readonly a {
		cursor: default;
	}
}
@media print {
	.br-theme-bars-pill .br-widget a {
		border: 1px solid #b3b3b3;
		border-left : none;
		background: white;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		&.br-active, &.br-selected {
			border: 1px solid black;
			border-left : none;
			background: white;
			color: black;
		}
		&:first-child {
			border-left : 1px solid black;
		}
	}
}
.br-theme-bars-reversed {
	.br-widget {
		height: 25px;
		white-space: nowrap;
		a {
			display: block;
			width: 22px;
			height: 22px;
			float: left;
			background-color: #bef5e8;
			margin: 1px;
			font-size: 15px;
			font-weight: 400;
			line-height: 1.4;
			color: #50E3C2;
			text-align: center;
			&.br-active, &.br-selected {
				background-color: #50E3C2;
				color: white;
			}
		}
		.br-current-rating {
			line-height: 1.3;
			float: left;
			padding: 0 20px 0 20px;
			color: #50E3C2;
			font-size: 17px;
			font-weight: 400;
		}
	}
	.br-readonly {
		a {
			cursor: default;
			&.br-active, &.br-selected {
				background-color: #7cead1;
			}
		}
		.br-current-rating {
			color: #7cead1;
		}
	}
}
@media print {
	.br-theme-bars-reversed .br-widget {
		a {
			border: 1px solid #b3b3b3;
			background: white;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			&.br-active, &.br-selected {
				border: 1px solid black;
				background: white;
			}
		}
		.br-current-rating {
			color: black;
		}
	}
}
@media (max-width:480px){
	.stars-example-fontawesome-sm .br-theme-fontawesome-stars .br-widget a{
		display:inline !important;
	}
}