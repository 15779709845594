/*------ Border-Radius ------*/

.br-7 {
	border-radius: 7px;
}

.br-ts-7 {
	border-top-left-radius: 7px !important;
}

.br-ts-10 {
	border-top-left-radius: 1rem !important;
}

.br-bs-7 {
	border-bottom-left-radius: 7px !important;
}

.br-bs-10 {
	border-bottom-left-radius: 1rem !important;
}

.br-te-7 {
	border-top-right-radius: 7px !important;
}

.br-te-10 {
	border-top-right-radius: 1rem !important;
}

.br-be-7 {
	border-bottom-right-radius: 7px !important;
}

.br-be-10 {
	border-bottom-right-radius: 1rem !important;
}

.br-ts-0 {
	border-top-left-radius: 0 !important;
}

.br-bs-0 {
	border-bottom-left-radius: 0 !important;
}

.br-te-0 {
	border-top-right-radius: 0 !important;
}

.br-be-0 {
	border-bottom-right-radius: 0 !important;
}

.border {
	border: 1px solid #e8ebf3 !important;
}

.br-100 {
	border-radius: 100% !important;
}

.br-4 {
	border-radius: 4px !important;
}

.br-2 {
	border-radius: 2px !important;
}

.br-ts-2 {
	border-top-left-radius: 2px !important;
}

.br-bs-2 {
	border-bottom-left-radius: 2px !important;
}

.br-te-2 {
	border-top-right-radius: 2px !important;
}

.br-be-2 {
	border-bottom-right-radius: 2px !important;
}

@media (min-width: 992px) {
	.br-md-2 {
		border-radius: 2px !important;
	}
	.br-ts-md-2 {
		border-top-left-radius: 2px !important;
	}
	.br-bs-md-2 {
		border-bottom-left-radius: 2px !important;
	}
	.br-te-md-2 {
		border-top-right-radius: 2px !important;
	}
	.br-be-md-2 {
		border-bottom-right-radius: 2px !important;
	}
	.br-md-0 {
		border-radius: 0 !important;
	}
	.br-ts-md-0 {
		border-top-left-radius: 0 !important;
	}
	.br-bs-md-0 {
		border-bottom-left-radius: 0 !important;
	}
	.br-te-md-0 {
		border-top-right-radius: 0 !important;
	}
	.br-be-md-0 {
		border-bottom-right-radius: 0 !important;
	}
	.Realestate-content .select2-container--default .select2-selection--single {
		border-radius: 0 !important;
	}
}

.br-0 {
	border-radius: 0 !important;
}


/*---- Borders ----*/

.border-top {
	border-top: 1px solid #e8ebf3 !important;
}

.border-end {
	border-right : 1px solid #e8ebf3 !important;
}

.border-bottom {
	border-bottom: 1px solid #e8ebf3 !important;
}

.border-start {
	border-left : 1px solid #e8ebf3 !important;
}

.border-0 {
	border: 0 !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-end-0 {
	border-right : 0 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-start-0 {
	border-left : 0 !important;
}
.border-lightdark{
	border-color:#e7ebf3 !important;
}
.border-lightdark-2 {
    border-color: #e1e2e8 !important;
}
.border-secondary {
	border-color: #868e96 !important;
}

.border-success {
	border-color: $success !important;
}

.border-info {
	border-color: #0dabb7 !important;
}

.border-warning {
	border-color: $yellow !important;
}

.border-danger {
	border-color: $danger !important;
}

.border-light {
	border-color: #f8f9fa !important;
}

.border-dark {
	border-color: #343a40 !important;
}

.border-white {
	border-color: $white !important;
}
.border-white-transparent{
	border-color:rgba($white, 0.1) !important;
}
.rounded {
	border-radius: 3px !important;
}

.rounded7 {
	border-radius: 7px !important;
}

.rounded-top {
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}

.rounded-end {
	border-top-right-radius: 3px !important;
	border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}

.rounded-start {
	border-top-left-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}