/*----- BreadCrumb -----*/

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0 !important;
	list-style: none;
	border-radius: 3px;
	left: 0;
	bottom: 0;
	padding: 0 !important;
	background-color: transparent !important;
}

.breadcrumb-item a {
	color: $white-8;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
	color: $color;
}

.breadcrumb-item+.breadcrumb-item {
	&::before {
		display: inline-block;
		padding-right: .5rem;
		padding-left: .5rem;
		color: $white-3;
		content: "/";
		opacity:0.5;
	}
	&:hover::before {
		text-decoration: underline;
		text-decoration: none;
	}
}

@media (max-width: 480px) {
	.breadcrumb-item+.breadcrumb-item {
		&::before {
			display: inline-block;
			padding-right: .2rem;
			padding-left: .2rem;
			color: $color;
			content: "/";
		}
		padding-left: 0;
	}
	.page-header {
		display: block !important;
		padding: 10px 0 !important;
	}
	.users-list .page-header {
		padding: 15px !important;
		.page-select {
			width: 100% !important;
			margin-bottom: 10px;
		}
	}
	.page-header .page-title {
		margin-bottom: 5px;
	}
}

.breadcrumb1 {
	display: flex;
	flex-wrap: wrap;
	padding: .75rem 1rem;
	margin-bottom: 1rem;
	list-style: none;
	background-color: $background;
	border-radius: 8px;
}

.breadcrumb-item1 {
	a {
		&:hover {
			text-decoration: underline;
		}
	}
	+.breadcrumb-item1 {
		&::before {
			display: inline-block;
			padding-right: .5rem;
			padding-left: .5rem;
			content: "/";
			opacity:0.5;
		}
		&:hover::before {
			text-decoration: underline;
			text-decoration: none;
		}
	}
	&.active {
		color: #a7b4c9;
	}
}

.breadcrumb-1,
.breadcrumb-2 {
	ol {
		position: relative;
		display: block;
		width: 100%;
		text-align: right;
	}
	li {
		text-align: right;
		display: contents;
	}
}

.breadcrumb-3,
.breadcrumb-4 {
	ol {
		position: relative;
		display: block;
		width: 100%;
		text-align: center;
	}
	li {
		display: contents;
		text-align: center;
	}
}

