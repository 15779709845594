.about-img {
	background: url(../images/other/about.jpg);
	background-size: cover;
	background-position: center;
	border-radius: 2px;
	margin-top: 1.5rem;
}

.login-img {
	background: url(../images/other/login.jpg);
	background-size: cover;
	width: 100%;
	height: 100%;
}

.error-img {
	background: url(../images/other/about.jpg);
	background-size: cover;
	width: 100%;
	height: 100%;
	.card {
		background: $white-2;
		box-shadow: 0 0.0625em 0.1875em 0 #076271, 0 0.5em 0 -0.25em $white-3, 0 0.5em 0.1875em -0.25em #086372, 0 1em 0 -0.5em rgba(255, 255, 255, 0.35), 0 1em 0.1875em -0.5em #096675;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;		
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
}

hr.divider {
	padding: 0;
	border: none;
	border-top: solid 1px #e8ebf3;
	color: #333;
	text-align: center;
	margin: .5rem 0;
}

.construction-img {
	background: url(../images/other/construction.jpg);
	background-size: cover;
}

.z-index-10 {
	z-index: 10;
}

.constuction-logo {
	border-bottom: 3px solid;
	border-radius: 3px;
}

.construction-image {
	background: url(../images/photos/construction.png);
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	&:before {
		position: fixed;
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		z-index: 1;
		left: 0;
		right: 0;		
	}
}

.error-image {
	background: url(../images/photos/error.jpg);
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	&:before {
		position: fixed;
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		z-index: 1;
		left: 0;
		right: 0;		
	}
}

.construction {
	z-index: 1;
	.btn {
		&.btn-icon {
			border: 0px solid rgba($white,0.1);
			text-align: center;
			padding: 0;
			font-size: 16px;
			color: rgba($white, 0.9);
			margin: 0 3px;
			border-radius: 8px;
			background: rgba($white,0.1);
			width: 40px;
			height: 40px;
			line-height: 42px;
		}
	}
	.form-control {
		border: 1px solid $white;
		width: 60%;
	}
	.input-group-1 {
		margin: 0 auto;
	}
	h3 {
		color: $white;
		font-size: 3rem;
		font-weight: 400 !important;
		margin-bottom: 0.4em !important;
		letter-spacing: 0;
		padding-bottom: 0;
		line-height: 1.2;
	}
	p {
		margin-bottom: 0;
		font-weight: 300;
		font-size: 1.1em;
		letter-spacing: .06667em;
		color: $white-7;
	}
}

.construction-image {
	.card {
		background: rgb(255, 255, 255);
	}
}

