/*----- Position Elements -----*/

.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed !important;
}

.position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
	.sticky-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.sr-only-focusable {
	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		overflow: visible;
		clip: auto;
		white-space: normal;
	}
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(40, 37, 89, 0.075) !important;
}

.shadow {
	box-shadow: 0 8px 20px 0 rgba(40, 37, 89, 0.1) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(40, 37, 89, 0.175) !important;
}

.shadow-none {
	box-shadow: none !important;
}