$background:#ececf3;
$color:#495057;
$border-color:#e8ebf3;

/*Color variables*/
$primary1:#7167ff;
$primary2:#0b75b3;
$primary3:#2a34b7;
$primary4:#27a9e2;
$primary5:#158878;
$primary6:#711b8a;
$primary7:#8c37ff;
$primary8:#007bff;
$primary9:#bb4123;
$primary10:#556ee6;

$secondary1:#f96768;
$secondary2:#ec296b;
$secondary3:#ed6f35;
$secondary4:#ec296b;
$secondary5:#e91e63;
$secondary6:#e67c18;
$secondary7:#f94a36;
$secondary8:#ea167c;
$secondary9:#113661;
$secondary10:#f46a6a;

$info:#50a5f1;
$success:#34c38f;
$warning:#f1b44c;
$danger:#f46a6a;
$blue:#467fcf;
$yellow:#ffa22b;
$teal:#05e6e6;
$purple:#604dd8;
$dark:#1d2124;
$indigo:#6574cd;
$white:#fff;
$black:#000;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);
