/*----- Navigation ------*/

.nav-item.show .dropdown-menu.dropdown-menu-arrow.show {
	top: -1px !important;
}

.nav {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav-link {
	display: block;
	padding: .2rem .9rem;
	&.active {
		color: $white;
	}
	&:focus,
	&:hover {
		text-decoration: none;
	}
	&.disabled {
		color: #868e96;
	}
}

.nav-tabs {
	.nav-link {
		border: 0;
		border: 1px solid transparent;
		border-radius: 0;
		color: inherit;
		color: $color;
		transition: .3s border-color;
		font-weight: 500;
		padding: 1rem 1.6rem;
		font-size: 13px;
		text-align: center;
		&:focus,
		&:hover {
			border: 0;
		}
	}
	.nav-item.show .nav-link,
	.nav-link.active {
		border: 0;
	}
	.dropdown-menu {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

.nav-pills {
	.nav-link {
		border-radius: 3px;
		&.active {
			color: $white;
		}
	}
	.show>.nav-link {
		color: $white;
	}
}

.nav-fill .nav-item {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	text-align: center;
}

.nav-justified .nav-item {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	text-align: center;
}

.nav.nav-pills.nav-stacked.labels-info p {
	color: #9d9f9e;
	font-size: 11px;
	margin-bottom: 0;
	padding: 0 22px;
}

.nav-item1 {
	padding: 10px;
	min-width: 2rem;
	transition: .3s color;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.nav1 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	background: $background;
}

.nav-item1 {
	&:hover:not(.disabled),
	&.active {
		color: $white;
	}
	.nav-link.disabled {
		opacity: 6;
		cursor: default;
		pointer-events: none;
	}
}

.nav1.bg-primary .nav-item1 .nav-link.disabled {
	color: $white-5;
}

.nav-tabs {
	.nav-item1 {
		&.nav-link {
			border: 1px solid transparent;
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
		}
		.nav-link {
			border: 0;
			color: inherit;
			margin-bottom: -1px;
			color: #a7b4c9;
			transition: .3s border-color;
			font-weight: 400;
			padding: 1rem 10px;
			&:hover:not(.disabled),
			&.active {
				color: $white;
			}
			&.disabled {
				opacity: .6;
				cursor: default;
				pointer-events: none;
			}
		}
		margin-bottom: 0;
		position: relative;
		margin-bottom: -1px;
		i {
			margin-right: .25rem;
			line-height: 1;
			font-size: 0rem;
			width: .875rem;
			vertical-align: baseline;
			display: inline-block;
			margin-right: 10px;
		}
		&:hover .nav-submenu {
			display: block;
		}
	}
	.nav-submenu {
		display: none;
		position: absolute;
		background: $black;
		background: $white;
		border: 1px solid #e8ebf3;
		border-top: none;
		z-index: 10;
		box-shadow: 0 1px 2px 0 $black-05;
		min-width: 10rem;
		border-radius: 0 0 3px 3px;
		.nav-item1 {
			display: block;
			padding: .5rem 1rem;
			color: #a7b4c9;
			margin: 0 !important;
			cursor: pointer;
			transition: .3s background;
			&.active {
				color: $blue;
			}
			&:hover {
				color: #5f6877;
				text-decoration: none;
				background: rgba(0, 0, 0, 0.024);
			}
		}
	}
}

.nav-item,
.nav-link {
	min-width: 2rem;
	transition: .3s color;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.nav-tabs {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: $black;
	border-bottom: 0;
}

.navtab-wizard {
	padding:10px;
	li {
	}
	&.nav-tabs .nav-link {
		width: 100%;
		text-align: center;
		display: block;
		border-radius: 8px;
		margin: 0 3px;
		font-size: 16px;
		font-weight: 500;
		background: #fff;
		padding: 0.55rem 1.5rem;
		border: 1px solid $border-color;
	}
	border-bottom: 1px solid $border-color !important;
}

@media (max-width: 560px) {
	.navtab-wizard.nav-tabs li {
		width: 100%;
		display: contents;
	}
}

.nav-tabs {
	.nav-link.disabled {
		opacity: .4;
		cursor: default;
		pointer-events: none;
		color: #868e96;
		background-color: transparent;
		border-color: transparent;
	}
	.nav-item {
		margin-bottom: 0;
		position: relative;
		margin-bottom: -1px;
		&:hover .nav-submenu {
			display: block;
		}
	}
}

.nav-item:hover .dropdown-menu {
	display: block;
}

.nav-link:hover .dropdown-menu {
	display: block;
	&.show {
		display: block;
	}
}

.nav-item {
	.nav-link:hover .mega-dropdown .nav-item .nav-link .sub-item,
	&:hover .sub-item {
		display: block;
	}
}

.nav-link:hover .sub-item {
	display: block;
	display: block;
}

@media (max-width: 1279px) and (min-width: 992px) {
	.nav-tabs .nav-link {
		padding: 1rem .95rem;
		font-size: 13px;
	}
}

.nav-tabs {
	.nav-item i {
		margin-right: .25rem;
		line-height: 1;
		font-size: 0rem;
		width: .875rem;
		vertical-align: baseline;
		display: inline-block;
		margin-right: 10px;
	}
	.nav-submenu .nav-item {
		display: block;
		padding: .5rem 1rem;
		color: #a7b4c9;
		margin: 0 !important;
		cursor: pointer;
		transition: .3s background;
		&.active {
			color: $blue;
		}
		&:hover {
			color: #5f6877;
			text-decoration: none;
			background: rgba(0, 0, 0, 0.024);
		}
	}
}

.navresponsive-toggler {
	font-size: 14px;
	line-height: 2.4;
	color: #a7b4c9;
	width:100%;
	display:block;
	span {
		float: left;
	}
}
.nav1.nav-tabs .nav-item1 .nav-link{
	border-radius:7px;
}