/*----- Footers  -----*/

.footer {
	background: $white;
	border-top: 1px solid #e8ebf3;
	font-size: .875rem;
	padding: 1.25rem 0;
	color: #a7b4c9;
	.social {
		ul li {
			float: left;
			padding: 7px;
			a {
				color: #a7b4c9;
			}
		}
		margin: 0 auto;
	}
	a:not(.btn) {
		color: #1f252d;
	}
}

@media print {
	.footer {
		display: none;
	}
}

#footer,
.fc-toolbar .ui-button,
.fileinput .thumbnail {
	text-align: center;
}

.four-zero {
	text-align: center;
	footer>a {
		text-align: center;
	}
}

.ie-warning,
.login-content,
.login-navigation {
	text-align: center;
}

.pt-inner {
	text-align: center;
	.pti-footer>a {
		text-align: center;
	}
}

.footerimg img {
	width: 35px;
	height: 35px;
}

.footer-main {
	.social li {
		float: left;
		margin-right: 15px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: #a7b4c9;
			line-height: 0;
		}
	}
	.payments li {
		float: left;
		margin-right: 15px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: $white-6;
			line-height: 0;
		}
	}
	h6 {
		font-size: 18px;
		margin-bottom: 1.2rem;
	}
}

footer .border-bottom {
	border-bottom: 1px solid rgba(167, 180, 201, 0.1) !important;
}

.footer-main {
	p {
		color: $white-7;
	}
	a {
		line-height: 2;
		transition: opacity .2s;
		color: $white-6;
	}
	&.footer-main1 {
		a {
			color: $color;
		}
		.form-control {
			border: 1px solid #e8ebf3 !important;
		}
	}
}

.footer-links a {
	color: $white-8;
	&:hover {
		text-decoration: none;
	}
}

@media (max-width: 992px) {
	.footer-main h6 {
		margin-top: 2rem;
	}
	footer.bg-dark {
		.text-start {
			text-align: center !important;
		}
		.social {
			li {
				float: none;
				text-align: center;
			}
			text-align: center;
		}
	}
	.footer-main .row div:first-child h6 {
		margin-top: 0;
	}
}

.footer-main {
	padding-top: 3rem;
	padding-bottom: 3rem;
	border-top: 1px solid rgba(167, 180, 201, 0.1);
	border-bottom: 1px solid rgba(167, 180, 201, 0.1);
}

footer .border-top {
	border-top: 1px solid rgba(167, 180, 201, 0.1) !important;
}

.footer-main .form-control {
	border: 0 !important;
}

.footer-payments {
	padding-left: 0;
	list-style: none;
	margin: 0;
	line-height: 1.25rem;
	li {
		display: inline-block;
		padding: 0 11px;
		font-size: 20px;
	}
}

.footer-main img {
	width: 80px;
	border: 0px solid $white-2;
	padding: 0px;
}

.footer-links a{
	position:relative;
	color: $white-6;
    padding: 3px 0;
    padding-right: 20px !important;
	font-size:12px;
	&:focus{
		text-decoration:none;
	}
	&:hover{
		text-decoration:none;
	}
	&:before {
		content: '';
		position: absolute;
		width: 1px;
		height: 12px;
		right: 7px;
		opacity: 0.1;
		background: $white;
		display: block;
		top: 9px;
	}
	&:last-child:before{
		display:none;
	}
}
.footer-icon-circle{
	i{
		width: 25px;
		height: 25px;
		display: inline-block;
		text-align: center;
		background:$white-1;
		line-height: 25px;
		border-radius: 8px;
		margin-bottom:6px;
	}
}
footer,
.main-footer {
	display: block;
	margin-top: auto;
}

.footer-special-icons a{
	width: 2rem;
    display: inline-block;
    height: 2rem;
    text-align: center;
    border-radius: 100px;
    line-height: 2.1rem;
    border-radius: 8px;
	background:#f2f3f8;
}