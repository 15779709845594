.accordion .card {
	&:not(:first-of-type) {
		&:not(:last-of-type) {
			border-bottom: 0;
			border-radius: 0;
		}
		.card-header:first-child {
			border-radius: 0;
		}
	}
	&:first-of-type {
		border-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	&:last-of-type {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

.details-accordion {
	.card .card-header[aria-expanded="true"].collapsed{
		color:$color;
	}
	.card-header{
		text-decoration:none;
		color:inherit;
		padding:1rem 1.5rem;
		&.collapsed:before {
			content: "\e9af";
			position: absolute;
			font-family: feather !important;
			right: 20px;
			top: 17px;
			font-size: 18px;
			transition: all .5s;
			transform: scale(1);
		}
		&:before {
			content: "\e994";
			position: absolute;
			font-family: feather !important;
			right: 20px;
			top: 17px;
			font-size: 18px;
			transition: all .5s;
			transform: scale(1);
		}
	}
	&.active a:before {
		content: ' ';
		transition: all .5s;
		transform: scale(0);
	}
}
.details-accordion .card .card-header[aria-expanded="false"] {
    border-bottom: 0px ;
}
.details-accordion .card .card-header[aria-expanded="true"].collapsed {
    border-bottom: 0px ;
}